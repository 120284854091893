<template>
  <div class="view-authConfirm" :style="{ paddingBottom: type == 1 ? '70px' : '0' }">
    <HeadProcess active="2" />
    <div class="confirmForm">
      <div class="formTitle">
        {{ ComAuthInfo.comName }}
        <span><van-icon name="warning" />未认证</span>
      </div>
      <div class="authMethod" v-if="type == 1">
        <div class="title">选择认证方式</div>
        <van-radio-group v-model="authType" class="list">
          <van-radio name="1" class="item">
            <div class="name">法人在线签署认证授权书（推荐）</div>
            <div class="detail">
              适合有法定代表人的企业与个体工商户类型的企业机构；认证操作简便、无需对公打款
            </div>
          </van-radio>
        </van-radio-group>
      </div>
      <div class="authContent" v-if="type == 2">
        <div class="title">选择认证方式：法人在线签署认证授权书</div>
        <div class="title">认证授权书</div>
        <div class="authImg" @click="HandleclickImg">
          <img :src="getImgUrl(legalCreditUrl)" alt />
        </div>
        <ul class="handleList">
          <li class="item">
            <div class="detail">
              <div class="name">
                我是法定代表人（{{ ComAuthInfo.comLegalName }}）
              </div>
              <div class="tag">在线完成认证授权书的签署</div>
            </div>
            <div class="btn" @click="goSign">开始签署</div>
          </li>
          <li class="item">
            <div class="detail">
              <div class="name">我不是法定代表人</div>
              <div class="tag">发送签署链接给法定代表人</div>
            </div>
            <div class="btn" @click="copy">复制链接转发</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="button-box" v-if="type == 1">
      <van-button plain type="info" @click="back">上一步</van-button>
      <van-button type="info" @click="updateComAuthType">确认</van-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ImagePreview,Dialog  } from 'vant'
import Apis from '@/api/creadit'
import { copyUrl } from '@/utils'
import getCreditToken from '@/utils/getCreditToken'
import HeadProcess from './Components/headProcess.vue'

export default {
  components: {
    HeadProcess,
  },
  data() {
    return {
      ComAuthInfo: {},
      authType: '1', // 1线上 2线下
      type: 1,
      url: null,
      token: '',
      copyText: undefined,
      isLegalPerson: false,
      legalCreditUrl: '',
    }
  },
  computed: {
    ...mapState({
      systemInfo: state => state.system,
    }),
    // 是否为法人
    // isLegalPerson() {
    //   return this.ComAuthInfo.comLegalIdNum === this.userIdCardNumber
    // },
    // 当前登录的用户身份证号
    userIdCardNumber() {
      return this.userInfo.idCardNo
    },
    userInfo() {
      return this.$store.getters.getUserInfo
    },

    comId() {
      return this.$route.query?.comId || this.userInfo.comId
    },
  },
  async created() {
    await this.validLegalFourElements()
    // this.token = await getCreditToken(1)
    await this.getComAuthInfo()
    await this.getSignFile()
  },
  async mounted() {
    try {
      this.$toast.loading({
        message: '加载中',
        forbidClick: false,
        loadingType: 'spinner',
      })
      let type
      if (this.isLegalPerson) {
        type = 1
      } else {
        type = 0
      }
      this.token = await getCreditToken(type)
    } catch (error) {
      console.error(error)
    } finally {
      this.$toast.clear()
    }
  },
  methods: {
    getSignFile() {
      Apis.getSignFile({
        comId: this.comId,
        userId: this.userInfo.userId,
      }).then(res => {
        this.legalCreditUrl = res.legalCreditUrl
        // this.comName = res.comName
      })
    },
    // 获取企业提交的信息
    async validLegalFourElements() {
      try {
        const model = { comId: this.comId }
        const data = await Apis.validLegalFourElements(model)
        this.isLegalPerson = data.legal
      } catch (error) {
        console.error(error)
      }
    },
    // 企业认证--选择线上线下
    async updateComAuthType() {
      // 实名认证
      const { creditStatus, } = this.userInfo
      if (creditStatus !== 1) {
        const type = 2
        const token = await getCreditToken(type)
        // 个人认证
        Dialog.alert({
          title: '提示',
          message: '您还没有认证,请先去实名认证',
          confirmButtonText: '去认证',
          className: 'sign-detail-dialog',
        }).then(() => {
          if (token) {
            const redirectUrl = 'AuthConfirm'
            this.$router.push({
              name: 'nameAndIdentitycard',
              query: {
                token,
                redirectUrl
              },
            })
          }
        })
        return
      }

      Apis.updateComAuthType({
        authType: this.authType,
        comId: this.comId,
      }).then(res => {
        this.type = 2
      })
    },
    // 获取企业认证信息
    getComAuthInfo() {
      Apis.getComAuthInfo({
        comId: this.comId,
      }).then(res => {
        this.ComAuthInfo = res
        // 细粒度认证状态：0.营业执照识别未提交 1.营业执照待审核 2.营业执照审核拒绝 3.企业三方认证成功 4.选择线上签署页面 5.选择线下签署页面 6.未认证
        if (res.creditStatusSelf === 3) {
          this.type = 1
        } else if (res.creditStatusSelf === 4) {
          this.type = 2
        } else if (res.creditStatusSelf === 5) {
          // 5.选择线下签署页面
        } else {
          this.$router.replace('my')
        }
        this.urlLink()
      })
    },
    async goSign() {
      const data = await Apis.validLegal({
        comId: this.comId,
        userId: this.userInfo.userId,
      })
      // 200:一致去签署 201:身份不一致 204:未注册 205:未实名
      if (data.code === 201) {
        this.$toast('当前实名身份与法人身份不符，请发送签署链接给法定代表人')
      } else if (data.code === 205) {
        await this.$dialog.alert({
          message: '您还未完成实名认证，请先去完成实名认证，再重新操作',
          showCancelButton: true,
          confirmButtonText: '去认证',
        })
        // 未实名认证，去个人认证    企业认证0  法人认证1  个人认证2
        const token = await getCreditToken(1)
        // const redirectUrl
        const redirectUrl = 'AuthConfirm'
        this.$router.push({
          path: `/nameAndIdentitycard`,
          query: {
            token,
            redirectUrl,
          },
        })
      } else {
        const token = await getCreditToken(1)
        this.$router.push({
          path: '/nameAndIdentitycard',
          query: {
            token,
          },
        })
      }
    },
    back() {
      window.history.back()
    },
    async urlLink() {
      const { token } = this
      const url = `${window.location.origin}/nameAndIdentitycard?token=${token}`
      this.copyText = `【${this.systemInfo.company}】您的企业《${this.ComAuthInfo.comName}》正在${this.systemInfo.company}平台申请企业认证，需要您亲自签署《认证授权书》，请点击链接完成签署${url}`
    },
    async copy() {
      await this.$copyText(this.copyText)
      this.$toast({
        message: '复制链接成功',
      })
    },
    HandleclickImg() {
      ImagePreview({
        images: [this.legalCreditUrl],
        showIndex: false,
        onClose() {
          // do something
        },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.view-authConfirm {
  background: #f4f5f6;

  .process-container {
    background-color: #fff;
    margin-bottom: 8px;
  }

  .confirmForm {
    padding: 24px 16px;
    background-color: #fff;

    .formTitle {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: bold;
      text-align: center;
      color: #111a34;
      line-height: 25px;
      margin-bottom: 20px;

      span {
        display: flex;
        align-items: center;
        margin-left: 17px;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: bold;
        text-align: left;
        color: #f15643;
        line-height: 16px;

        .van-icon {
          font-size: 16px;
          margin-right: 4px;
        }
      }
    }

    .title {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #111a34;
      line-height: 20px;
      margin-bottom: 16px;
    }

    .authMethod {
      .list {
        .item {
          box-sizing: border-box;
          padding: 16px;
          background: rgba(22, 118, 255, 0.04);
          border: 1px solid #1676ff;
          border-radius: 2px;
          align-items: flex-start;
          margin-bottom: 16px;

          .name {
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            color: #111a34;
            line-height: 20px;
            margin-bottom: 11px;
          }

          .detail {
            font-size: 12px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            color: #677283;
            line-height: 18px;
          }

          /deep/ .van-radio__label {
            margin-left: 12px;
          }

          /deep/ .van-radio__icon {
            padding-top: 3px;
            height: auto;

            /deep/ .van-icon {
              width: 14px;
              height: 14px;
              border-color: #1676ff;
            }

            /deep/ .van-icon::before {
              content: '';
            }
          }

          /deep/ .van-radio__icon--checked {
            /deep/ .van-icon {
              position: relative;
              background: #fff;
            }

            /deep/ .van-icon::before {
              position: absolute;
              left: 2px;
              top: 2px;
              content: '';
              width: 8px;
              height: 8px;
              background-color: #1676ff;
              border-radius: 50%;
            }
          }
        }
      }
    }

    .authContent {
      .authImg {
        width: 137px;
        height: 194px;
        box-shadow: 0px 1px 3px 0px rgba(194, 194, 194, 0.53);
        margin-bottom: 16px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .handleList {
        .item {
          width: 343px;
          height: 81px;
          padding: 16px;
          box-sizing: border-box;
          border: 1px solid #f5f5f5;
          border-radius: 2px;
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .detail {
            .name {
              font-size: 14px;
              font-family: PingFangSC, PingFangSC-Regular;
              font-weight: 400;
              color: #111a34;
              line-height: 20px;
              margin-bottom: 11px;
            }

            .tag {
              font-size: 12px;
              font-family: PingFangSC, PingFangSC-Regular;
              font-weight: 400;
              color: #677283;
              line-height: 18px;
            }
          }

          .btn {
            flex-shrink: 0;
            width: 94px;
            height: 32px;
            background: #1676ff;
            border-radius: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: left;
            color: #ffffff;
            line-height: 17px;
          }
        }
      }
    }
  }

  .button-box {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background: @WHITE;
    box-shadow: 0px -6px 10px 0px rgba(230, 234, 237, 0.3);
    padding: 10px 20px calc(@safe-area + 10px);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .van-button {
      width: 48%;
      height: 44px;
      border-radius: 4px;
      font-size: 18px;
      font-weight: 500;
    }
  }
}
</style>
